<template>
  <div>
    <iframe :src="publicPath + 'animate/feiting/index.html'" scrolling="no" class="iframe" frameborder="0" ref="iframe" :height="iframeHeight"></iframe>
  </div>
</template>

<script>

// 开奖延时 (秒)
// const kOpenDelay = 600 / 5
// const kOpenDelay = 10
// 倒计时延迟
const kCountdownDelay = 3
// 动画播放时间
const kAnimateSec = 8
// 开奖结果显示时间
const kResultPageDelay = 15
// 请求间隔
const kRequestInterval = 2

export default {
  name: 'xyft168',
  props: {
    code: String,
    id: String
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      iframeHeight: 870,
      midGame: false,
      timer: null,
      currentOpenCode: null
    }
  },
  mounted () {
    this.$refs.iframe.onload = () => {
      // 替换图片
      if (this.id === '1') {
        this.getIframeContentWindow(window => {
          window.$('.logo img').attr('src', 'images/logo_168.png')
        })
      }
      this.iframeSizeFit()
      this.getState()
      // this.requestGetLive()
      // this.startTimer()
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  },
  methods: {
    iframeSizeFit () {
      this.getIframeContentWindow(window => {
        const originWidth = 1334
        const originHeight = 870
        const currentWidth = this.$refs.iframe.offsetWidth
        const zoom = currentWidth / originWidth

        const html = window.document.documentElement
        html.style.zoom = zoom

        this.iframeHeight = originHeight * zoom

        window.ifopen = true
      })
    },
    getState () {
      // this.$loading.show()
      this.$api.live(this.code, this.id).then(({ data }) => {
        this.currentOpenCode = data.opencode
        this.setupData(data)
        // let nextTime = data.next_time + kOpenDelay
        let nextTime = data.next_time
        if (nextTime <= 0) {
          nextTime = kCountdownDelay
        }
        this.startCountdown(nextTime, () => {
          this.timer = setTimeout(() => {
            clearTimeout(this.timer)
            this.timer = null
            // kAnimateSec 秒后 开奖
            this.runLottery()
          }, kAnimateSec * 1000)
        })

        // this.$loading.hide()
      }).catch(err => {
        console.log(err)
        // this.$loading.hide()
        // this.$message.error(err)
      })
    },
    // 开奖
    runLottery () {
      // 请求开奖结果
      this.$api.live(this.code, this.id).then(({ data }) => {
        const currentResult = data.opencode_arr.map((item) => {
          return parseInt(item)
        }).join(',')
        if (this.currentOpenCode !== data.opencode) {
          this.currentOpenCode = data.opencode
          this.setupData(data)
          this.finishgame(currentResult)
          this.timer = setTimeout(() => {
            clearTimeout(this.timer)
            this.timer = null
            this.getState()
          }, kResultPageDelay * 1000)
        } else {
          this.timer = setTimeout(() => {
            this.runLottery()
            clearTimeout(this.timer)
          }, kRequestInterval * 1000)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    startCountdown (count, handler) {
      this.getIframeContentWindow(window => {
        window.startcountdown(count)
        if (handler) {
          this.timer = setTimeout(() => {
            clearTimeout(this.timer)
            this.timer = null
            handler()
          }, count * 1000)
        }
      })
    },
    midgame () {
      this.getIframeContentWindow(window => {
        window.midgame()
      })
    },
    finishgame (result) {
      this.getIframeContentWindow(window => {
        window.finishgame(result, 30)
      })
    },
    resultpage () {
      this.getIframeContentWindow(window => {
        window.resultpage('10,1,3,9,8,7,4,5,2,6')
      })
    },
    showcurrentresult () {
      this.getIframeContentWindow(window => {
        window.showcurrentresult('5,6,3,4,8,7,9,10,2,1')
      })
    },
    setupData (data) {
      // document.getElementById
      this.getIframeContentWindow(window => {
        // 设置当前号码
        // if (!this.midgame) { // 游戏进行中不进行设置
        const currentResult = data.opencode_arr.map((item) => {
          return parseInt(item)
        }).join(',')
        window.showcurrentresult(currentResult)
        // }

        const document = window.document
        // 设置剩余期数
        document.getElementById('currentdrawid').innerHTML = data.not_open
        // 设置期号
        document.getElementById('nextdrawtime').innerHTML = data.expect
        // 设置冠军和
        document.getElementById('stat1_1').innerHTML = data.sum
        document.getElementById('stat1_2').innerHTML = data.sum_big_small
        document.getElementById('stat1_3').innerHTML = data.sum_single_double

        // 设置龙虎
        document.getElementById('stat2_1').innerHTML = data.loong_tiger[0]
        document.getElementById('stat2_2').innerHTML = data.loong_tiger[1]
        document.getElementById('stat2_3').innerHTML = data.loong_tiger[2]
        document.getElementById('stat2_4').innerHTML = data.loong_tiger[3]
        document.getElementById('stat2_5').innerHTML = data.loong_tiger[4]
      })
    },
    getIframeContentWindow (handler) {
      handler(this.$refs.iframe.contentWindow)
    }
  }
}
</script>

<style lang="scss" scoped>
.iframe {
  width: 100%;
}
</style>
